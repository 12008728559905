import { useState, useEffect } from 'react';
import homeBanner from '../../images/all.png'
import Header from '../Header/Header';
import './HomeBanner.scss'

const Home = () => {
   const [isWhite, setIsWhite] = useState(true);
   useEffect(() => {
      const intervalId = setInterval(() => {
        setIsWhite((prevIsWhite) => !prevIsWhite);
      }, 500);
  
      // Clear interval when component unmounts or when you want to stop the animation
      return () => clearInterval(intervalId);
    }, []);
   return (
      <div className="home-banner">
         <div className="home-bg">
            <Header/>
            <div className="containerx">         
               <div className="text-box">
                  <h1>The <span>SEVEN</span><br/>HomeMade</h1>     
                  <a href="https://www.foodbooking.com/ordering/restaurant/menu?company_uid=c2493098-dc54-4c75-b4ac-3e1fd4aab238">
                     <h2 className={isWhite ? 'white' : 'red'}>POUR COMMANDER</h2>
                  </a>
               </div>
               <img className="imag-banner" src={homeBanner} alt="" />
            </div>
         </div>
      </div>
   );
};



export default Home;