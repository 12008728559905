import jsonData from '../data/products.json'

export   function findIt(name) {

    var i=0
    while(i<jsonData.length){
      if(jsonData[i].name===name)
        return i
      i++  
    }
  }


export function addZero(price){

    if(price===Math.floor(price))
      return price+".00"
    return price+"0"  

  }  