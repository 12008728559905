import CardItem from '../CardItem/CardItem'
import "./MenuComponents.scss"
import jsonData from '../../data/products.json'
import { findIt ,addZero} from '../../Functions/Functions'

function Kids() {


 

  return (
    <div id='kids' className='hams'>
        <div className='box'>
        <h1>Menus enfants</h1>
        <div className='container'>
        {jsonData.slice(findIt("Menu enfant"),findIt("Milkshake Maison")).map((item,index)=>(<CardItem key={index+findIt("Menu enfant")+1} name={item.name} price={addZero(item.price)} desc={item.desc} link={index+findIt("Menu enfant")+1} logo={item.img} />))} 

          </div>
        </div>
    </div>
  )
}

export default Kids