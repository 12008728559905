import CardItem from '../CardItem/CardItem'
import "./MenuComponents.scss"
import jsonData from '../../data/products.json'
import { findIt ,addZero} from '../../Functions/Functions'

function Panini() {




  return (
    <div id='paninis' className='hams'>
      <div className='box'>
        <h1>Paninis</h1>
        <div className='container'>
          {jsonData.slice(findIt("Panini viandes"),findIt("Assiette simple")).map((item,index)=>(<CardItem key={index+findIt("Panini viandes")+1} name={item.name} price={addZero(item.price)} desc={item.desc} link={index+1+findIt("Panini viandes")} logo={item.img} />))} 
        </div>
        </div>
    </div>
  )
}

export default Panini