import firebase from "firebase/compat/app"
import "firebase/compat/auth"

const app = firebase.initializeApp({
    apiKey: "AIzaSyAJ4L72ld6HKsIPSfHQFrcPGC-c4UNWOXY",
    authDomain: "saven-home-made-auth.firebaseapp.com",
    projectId: "saven-home-made-auth",
    storageBucket: "saven-home-made-auth.appspot.com",
    messagingSenderId: "346146803787",
    appId: "1:346146803787:web:2a67b79be9b2c2cf90fdce"
})

export const auth = app.auth()

export default app