import './CardItem.scss'
import {Link} from 'react-router-dom'

function CardItem({name,price,desc,logo,link}) {
  return (
    <Link className='item'  >
        <img className='logo-img' loading='lazy' src={logo} alt=''/>
            <div className='disc'>
                <h3>{name}</h3>
                <h4>{desc}</h4>
                {(price!=="0.00") ? <h5>{price}€</h5>: null}
            </div>
    </Link>
  )
}

export default CardItem