import React from 'react'
import "./MenuComponents.scss"
import CardItem from '../CardItem/CardItem'
import jsonData from '../../data/products.json'
import {addZero,findIt} from "../../Functions/Functions"



function Hamburger() {


  return (
    <div id='burgers' className='hams'>
      <div className='box'>
        <h1>Burgers</h1>
        <div className='container'>
        {jsonData.slice(findIt("Hamburger"),findIt("Tacos M")).map((item,index)=>(<CardItem key={index+findIt("Hamburger")+1} name={item.name} price={addZero(item.price)} desc={item.desc} link={index+findIt("Hamburger")+1} logo={item.img} />))} 
        </div>
      </div>
    </div>
  )
}

export default Hamburger