import CardItem from '../CardItem/CardItem'
import "./MenuComponents.scss"
import jsonData from '../../data/products.json'
import { findIt,addZero } from '../../Functions/Functions'



function Drinks() {


  return (
    <div id='drinks' className='hams'>
      <div className='box'>
        <h1>Boissons</h1>
        <div className='container'>
          {jsonData.slice(findIt("7up cherry")).map((item,index)=>(<CardItem key={index+findIt("7up cherry")+1} name={item.name} price={addZero(item.price)} desc={item.desc} link={index+findIt("7up cherry")+1} logo={item.img}/>))} 
        </div>
      </div>
    </div>
  )
}

export default Drinks