import { Link } from 'react-router-dom';
import logo from '../../images/logo.png'
import './Header.scss'
import { useState, useEffect } from 'react';

const Header = () => {
   const [isWhite, setIsWhite] = useState(true);
   useEffect(() => {
      const intervalId = setInterval(() => {
        setIsWhite((prevIsWhite) => !prevIsWhite);
      }, 500);
  
      // Clear interval when component unmounts or when you want to stop the animation
      return () => clearInterval(intervalId);
    }, []);

   return (
      <nav className="navbar">
         <div className="container">
            <Link to="/">
               <img className="logo-img" src={logo} alt=''></img>
            </Link>
            <div className="nav-item">
              <a className={isWhite ? 'white' : 'red'} href="https://www.foodbooking.com/ordering/restaurant/menu?company_uid=c2493098-dc54-4c75-b4ac-3e1fd4aab238&restaurant_uid=b223b90d-c0ec-4301-8cff-6c67a6dac4b7&facebook=true">Pour commender</a>
            </div>
         </div>
      </nav>
   );
};

export default Header;