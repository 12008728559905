import Services from "./../components/ServeOptions/Services";
import Hamburger from "./../components/MenuComponents/Hamburger";
import Tacos from '../components/MenuComponents/Tacos';
import Sandwich from '../components/MenuComponents/Sandwich';
import MenuTexMex from '../components/MenuComponents/MenuTexMex';
import TexMex from '../components/MenuComponents/TexMex';
import Salades from '../components/MenuComponents/Salades';
import Kids from '../components/MenuComponents/Kids';
import Assiettes from '../components/MenuComponents/Assiettes';
import Drinks from '../components/MenuComponents/Drinks';
import Desserts from '../components/MenuComponents/Desserts';
import Panini from '../components/MenuComponents/Panini';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import '../components/Extra//CheckBox.scss';
import StudentMenu from '../components/MenuComponents/StudentMenu';


function MainPage() {
  return (
    <div id="main">
    <Services/>
    <NavigationBar/>
    <Sandwich/>
    <Hamburger/>
    <Tacos/>
    <MenuTexMex/>
    <TexMex/>
    <Panini/>
    <Assiettes/>
    <Salades/>
    <Kids/>
    <StudentMenu/>
    <Desserts/>
    <Drinks/>
    </div>
  )
}

export default MainPage