import CardItem from '../CardItem/CardItem'
import "./MenuComponents.scss"
import jsonData from '../../data/products.json'
import { findIt,addZero } from '../../Functions/Functions'



function TexMex() {




  return (
    <div id='texmex' className='hams'>
      <div className='box'>
        <h1>Tex-Mex</h1>
        <div className='container'>
          {jsonData.slice(findIt("Wings"),findIt("Panini viandes")).map((item,index)=>(<CardItem key={index+1+findIt("Wings")} name={item.name} price={addZero(item.price)} desc={item.desc} link={index+1+findIt("Wings")} logo={item.img} />))} 
        </div>
      </div>
    </div>
  )
}

export default TexMex