import React from 'react';
import icon1 from '../../images/svgs/feature-icon-1.svg'
import icon2 from '../../images/svgs/feature-icon-2.svg'
import icon3 from '../../images/svgs/food-delivery.svg'
import './Services.scss'

const Feature = () => {
   return (
      <div className="feature-section">
         
               <div className="cont">
                  <div className="feature-box">
                     <div className="icon">
                        <img className='image' src={icon1} alt="" />
                     </div>
                     <h4>SUR PLACE</h4>
                  </div>
               
                  <div className="feature-box">
                     <div className="icon">
                        <img className='image' src={icon2} alt="" />
                     </div>
                     <h4>LIVRAISON</h4>
                  </div>
                  
                  <div className="feature-box">
                     <div className="icon">
                        <img className='image' src={icon3} alt="" />
                     </div>
                     <h4>À EMPORTER</h4>
                  </div>
               </div>
         
      </div>
   );
};

export default Feature;