import CardItem from '../CardItem/CardItem'
import "./MenuComponents.scss"
import jsonData from '../../data/products.json'
import { findIt,addZero } from '../../Functions/Functions'

function StudentMenu() {
  return (
    <div id='student' className='hams'>
    <div className='box'>
      <h1>Menu étudiant</h1>
      <h5 className='h4spec'>Sur place seulement, avec la présence de carte étudiant. Valables jusqu'à 18:00h.</h5>
      <div className='container'>
        {jsonData.slice(findIt("Sandwich kebab "),findIt("7up cherry")).map((item,index)=>(<CardItem key={index+findIt("Sandwich kebab ")+1} name={item.name} price={addZero(item.price)} desc={item.desc} link={index+1+findIt("Sandwich kebab ")} logo={item.img} />))} 
      </div>
    </div>
  </div>
  )
}

export default StudentMenu