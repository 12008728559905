import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer/Footer";
import Home from "./components/HomeBanner/HomeBanner"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import { ShoppingCartProvider } from "./context/ShoppingCartContext";



function App() {

  

  return (
    <div className="App">
      <ShoppingCartProvider>
        <Router>
        <Home/>
        <Routes>
          <Route path="/" element={<MainPage/>}/>
        </Routes>
        <Footer/>
        </Router>
      </ShoppingCartProvider>
    </div>
  );
}

export default App;
