import React from 'react';
import './Footer.scss'

const Footer = () => {
   return (
      <div className="footer-section">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-md-6">
                  <div className="footer-box">
                     <h5>  The <span>SEVEN</span><br/>HomeMade</h5>  
                     <p>57 Rue de Paris, 03200 Vichy, France</p>
                     <p>PHONE - +33 4 63 64 58 47</p>
                     <p>EMAIL - sevenhomemade1@gmail.com</p>
                  </div>
               </div>
               
               <div className="col-lg-4 col-md-6 mx-auto">
                  <div className="footer-box">
                     <h5>HORAIRES D'OUVERTURES</h5><br/>
                     <ul className="navbar-nav">
                        <li className="nav-item">Vendredi : <span>17:00 - 01:00</span></li>
                        <li className="nav-item">Le reste des jours : <span>11:00 - 01:00</span></li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className='footer-box'>
               <h5>MODE DE PAIEMENTS ACCEPTÉ</h5>
               <p>Espèces<br/>Carte bleue<br/>Tickets restaurant<br/>Carte ticket restaurant<br/>Chèques vacances</p>
            </div>
            <div className="copyright">
               <span> &copy; 7 HomeMade all Rights Reserved.</span><br/>
               <span>Développeur : abderraouf.khorchani@gmail.com</span>
            </div>
         </div>
      </div>
   );
};

export default Footer;