import { useEffect, useState } from "react"

export function useLocalStorage(key,initialValue){ 
    const [value,setValue]=useState(()=>{
        const jsonValue = localStorage.getItem(key)
        if (jsonValue!==null) return JSON.parse(jsonValue)
        return initialValue
    })

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value))
//        console.log('added',localStorage.getItem(key))
      })
    
      return [value, setValue]
}