import {ApolloClient,InMemoryCache,ApolloProvider,HttpLink,from} from '@apollo/client'
import { onError } from "@apollo/client/link/error"


    const errorLink = onError(({graphqlErrors , networkError})=>{
        if(graphqlErrors){
          graphqlErrors.map(({message,location,path})=>{
            alert(`graphql error ${message}`)
          })
        }
        if(networkError){
           console.log(networkError)
        }
      })
    
      const link = from([
        errorLink,
      new HttpLink({uri: "https://graphql.seven-homemade.fr:8080/graphql"})])
      //new HttpLink({uri: "http://localhost:8080/graphql"})])
    
 export     const client = new ApolloClient(
        {
          cache: new InMemoryCache(),
          link:  link
        }
      )
    


