import './NavigationBar.scss'
import {motion } from 'framer-motion'
import {useState,useRef,useEffect} from 'react'

function NavigationBar() {

    const [width,setWidth] = useState(0);
    const crsl = useRef();

    const jumpTo = (id) =>
    {
       document.getElementById(id).scrollIntoView();
    }
    
    function getOffset(el){
        const rect =el.getBoundingClientRect();
        return rect.top + window.scrollY;
    }

    function changeCss () {
        const Sandwich = document.getElementById('sandwich');
        const element = document.getElementById('navigation')
        this.scrollY+80 < getOffset(Sandwich) ? element.classList.add("nav-back") : element.classList.remove("nav-back");
     }
     window.addEventListener("scroll", changeCss, false);

     useEffect(() =>{
        setWidth(crsl.current.scrollWidth - crsl.current.offsetWidth);
    },[])

  return (
    <div id="navigation" className='dad'>
        <div className='bar'>
            <motion.div ref={crsl} className='nav'>
                <motion.div drag="x"  dragConstraints={{right:0, left:-width}} className='inner'>
                    <motion.button className='button' onClick={() => {jumpTo("sandwich")}}><h4>Sandwichs</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("burgers")}}><h4>Burgers</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("tacos")}}><h4>Tacos</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("menutexmex")}}><h4 className='sp2'>Menu Tex-Mex</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("texmex")}}><h4 className='sp'>Tex-Mex</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("paninis")}}><h4>Paninis</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("assiettes")}}><h4>Assiettes</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("salades")}}><h4>Salades</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("kids")}}><h4 className='sp2'>Menus enfants</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("student")}}><h4>Menu étudiant</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("desserts")}}><h4>Desserts</h4></motion.button>
                    <motion.button className='button' onClick={() => {jumpTo("drinks")}}><h4>Boissons</h4></motion.button>
                </motion.div>
            </motion.div>
        </div>
    </div>
  )
}

export default NavigationBar