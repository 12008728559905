import React,{createContext, useContext, useState,useEffect } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {auth} from '../firebase'

const ShoppingCartContext = createContext({})

export function useShoppingCart(){
    return useContext(ShoppingCartContext)
}

export function ShoppingCartProvider({children}){
    const [cartItems,setCartItems]= useLocalStorage("shopping-cart-key",[])
    const [user,setUser]=useLocalStorage("current-user","")
    const [isOpen,setIsOpen]=useState(false)
    const [cartQuantity,setCartQuantity]=useState(cartItems.length)
    const [currentUser,setCurrentUser]=useState()

    function signUp(email,password){
        return auth.createUserWithEmailAndPassword(email,password)
    }

    function signIn(email,password){
        return auth.signInWithEmailAndPassword(email,password)
    }

    useEffect(()=>{
       const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user)
        })
            return unsubscribe
    },[])

    const openCart = ()=>setIsOpen(true)
    const closeCart = ()=>setIsOpen(false)

    return( 
    <ShoppingCartContext.Provider value={{currentUser,signUp,signIn,cartItems,setCartItems,openCart,closeCart,cartQuantity,setCartQuantity,user,setUser}}>
            {children}
    </ShoppingCartContext.Provider>)

}
